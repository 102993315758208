.heatmap-row {
  display: flex;
  align-items: center;
}
.col-header {
  width: 7rem;
}

.hetmap-cell {
  width: 4rem;
  height: 4rem;
  padding-top: 20px;
  text-align: center;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}
