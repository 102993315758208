@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "../node_modules/primereact/resources/themes/mdc-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "antd/dist/antd.css"; 


@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.content blockquote:not(:last-child),
.content dl:not(:last-child),
.content ol:not(:last-child),
.content p:not(:last-child),
.content pre:not(:last-child),
.content table:not(:last-child),
.content ul:not(:last-child) {
  margin-bottom: 0;
}

.navbar {
  box-shadow: 0 1px 20px 0 rgb(46 61 73 / 20%);
}
.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}
.box {
  box-shadow: 4px 6px 24px 9px rgb(0 0 0 / 9%);
  border-radius: 0px;
}
.card {
  border: 1px solid #d5dbdb;
  box-shadow: none;
}

.title {
  font-size: 1rem;
}
.page-title {
  color: #363636;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.125;
}

.button.is-ghost {
  display: inherit;
}

@media screen and (min-width: 769px) {
  .modal-card,
  .modal-content {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 800px;
  }
}

.p-component {
  font-size: 0.9rem !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0 !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.9rem 0.4rem 0rem !important;
}

.p-paginator .p-dropdown {
  height: auto !important;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: "Open Sans", sans-serif;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
  color: #333;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(112, 112, 112, 0.04);
  color: #333;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text:enabled:focus,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):focus,
.p-button.p-button-secondary.p-button-outlined:enabled:focus,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-secondary
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:focus,
.p-buttonset.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:focus,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:focus,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):focus {
  background: rgba(112, 112, 112, 0.04);
}

.p-button {
  min-width: 2rem;
}

.modal {
  z-index: 1201;
}

.p-dropdown {
  width: 100%;
  padding: 4px;
}

.file-cta {
  background-color: #fff;
  color: #000;
}

.file-label:hover .file-cta {
  background-color: #fff;
  color: #000;
}

.p-dropdown-panel {
  z-index: 20000 !important;
  /* position: relative; */
  /* max-width: 600px !important; */
}

.p-dropdown-items {
  max-width: 800px !important;
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 920px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1241px;
  }
}

@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1090px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00b572;
  border-color: lightgray;
  outline: none;
}

.ant-tabs-ink-bar {
  background: #00ba75;
}

.ant-menu-title-content ::after {
  background: green;
}

.ant-menu-submenu-arrow {
  background-color: azure !important;
}

.ant-card {
  box-shadow: 5px 8px 20px 1px rgba(148, 147, 147, 0.6) !important;
}

.margin {
  margin-right: 10%;
}

.flex {
  display: flex;
}

.block {
  display: inline-block;
}

.sider-margin {
  margin-left: 120px;
}
.sider-margin2 {
  margin-left: 270px;
}

/* tags */

Th {
  text-transform: uppercase;
  white-space: nowrap;
}

.ant-modal-title {
  display: flex;
  align-items: center;
  text-align: center !important;
  margin: auto !important;
  width: auto !important;
}

/* label {
  text-transform: uppercase;
} */

.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.ant-card {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12) !important;
}

.center-spin {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 25% 50%;
  text-align: center;
  border-radius: 4px;
}

.letterGreen {
  background-color: #00ba75;
  color: white;
  font-size: 22px;
  font-weight: bold;
  width: 15px;
  margin-top: 15px;
}

.letterGray {
  background-color: lightgray;
  color: gray;
  font-size: 22px;
  font-weight: bold;
  width: 15px;
  margin-top: 15px;
}

.showImg {
  width: 35px;
  height: 35px;
  margin-right: 2vw;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
}

Option {
  color: "gray";
}

/*sidebar */
.sidebar-title {
  letter-spacing: 1px;
  font-size: 15px;
  /* margin-left: 1px; */
  /* line-height: 1; */
}

.s-title-margin {
  text-align: center;
  font-size: 14px;
  max-width: 50%;
  margin-left: 10px;
  line-height: -3px;
}

.sidebar-icons {
  width: 24px;
  height: 24px;
}

/* calender  */
/* .ant-picker-cell-inner::before */

.ant-picker-cell-inner {
  /* border : 1px solid transparent !important;
  color : white; */
  /* background: #26ac5f; */
  border-radius: 20px !important;
}

.ant-picker-cell-inner::before {
  /* border : 1px solid transparent !important; */
  color: #26ac5f !important;
  border-radius: 20px !important;
  /* background: #26ac5f; */
}

.card-without-padding .ant-card-body {
  padding: 0px !important;
}

/* .ant-modal-header.composeModal{
  padding : 0 !important
}
.ant-modal-header.composeModal{
  padding : 0 !important
} */

.table-edit-btn {
  cursor: pointer;
}

.add-icon-onboarding {
  margin-top: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-top: 5%;
}
.add-icon-onboarding-icon {
  color: gray;
  font-size: 30px;
  margin-right: 5px;
}
.add-icon-onboarding > div {
  margin: 0 5px;
}

/* .ant-radio-input {
  color: chartreuse !important;
} */

.ant-radio-checked .ant-radio-inner {
  border-color: #19a46c !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #19a46c;
}

.input-without-border.ant-input {
  border: 1px solid transparent;
  border-bottom: 1px solid lightgray;
  border-radius: 0px;
}

.onboarding-input.ant-picker-input::placeholder {
  color: #f0f0f0;
}

.custom-radio {
  margin: 10px 15px;
}

.gray-input.ant-input-affix-wrapper {
  /* background-color: lightgray !important; */
  border-radius: 0px;
}

/* utility */
.lib-input.ant-input {
  border-radius: 0px !important;
}

.green-name {
  background-color: #abe4aa;
  color: black;
  padding: 5px 10px;
  font-weight: 600;
}

.ant-picker-range {
  width: 250px !important;
  margin: 0 10px;
}

.utility-tasks-table .ant-card-body {
  padding: 0px !important;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 12px;
} */

