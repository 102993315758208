.p-inputtext {
  padding: 0.2rem !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  vertical-align: middle;
}

.p-inputtext.p-inputtext-sm {
  height: 40px;
}

/* .p-datatable table {
  border-collapse: collapse;
  width: auto;
  table-layout: fixed;
  overflow-x: scroll;
} */

.p-datatable {
  overflow-x: auto;
}

/* @media screen and (min-width: 1024px) {
  .container {
    max-width: 920px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1241px;
  }
} */
