.label {
  font-size: small;
  font-weight: 400;
}
.table-space {
  border-collapse: separate;
  border-spacing: 15px 0;
}
.boxes {
  height: 28px;
  width: 28px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
}
.well-managed {
  background-color: #629700;
}
.satisfactory {
  background-color: #aecb78;
}
.some-improve-opp {
  background-color: #ffee96;
}
.major-improve-opp {
  background-color: #e2b04d;
}
.unacceptable-risk-exp {
  background-color: #db4747;
}

.box {
  padding: 1.12rem !important;
}
